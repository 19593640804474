/*mobile screens*/
@media screen and (min-width: 0px) and (max-width: 800px) {
.news-container {
	width: 96%;
}
}

/*regular screens*/
@media screen and (min-width: 800px) and (max-width: 1200px) {
.news-container {
	width: 70%;
}
}

/*extra-large screens*/
@media screen and (min-width: 1200px) {
.news-container {
	width: 50%;
}
}



.news-container {
	display: flex;
	flex-direction: column;
	margin: auto;
	margin-top: 1rem;

}

.news-item {
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: white;
	margin-bottom: 1rem;
}

.news-image {
	width: 80%;
	margin: auto;
	max-height: 20rem;
	margin-bottom: 2rem;
}

.news-date {
	font-size: 15px;
	color: grey;
	text-align: center;
}

.news-header {
	font-size: 30px;
	text-align: center;

}

.news-body {
	width: 80%;
	margin: auto;
	font-size: 18px;
	padding-right: 1rem;
	padding-left: 1rem;
	padding-bottom: 2rem;
	white-space: pre-line;
}

