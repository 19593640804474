@media screen and (min-width: 800px) and (max-width: 1200px) {

.header-div {
	margin-left: 0rem;
	display: flex;
	align-items: center;
	
}

.header-text {
	font-family: Tahoma;
	color: white; 
	z-index: 4;
	font-size: 20px;
	text-decoration: none;
	margin-left:  1rem;
}

}

@media screen and (min-width: 1200px) {

.header-div {
	margin-left: 1rem;
	display: flex;
	align-items: center;
}

.header-text {
	font-family: Tahoma;
	color: white; 
	z-index: 4;
	font-size: 25px;
	text-decoration: none;
	margin-left:  1rem;
}

}


@media screen and (min-width: 0px) and (max-width: 800px) {
	
.header-div {
	
	height: 10%;
	display: flex;
	flex-wrap: wrap;
	align-content: center;
	text-align: center;
	margin: auto;
	
}

.header-text {
	
	color: white; 
	width: 60%;
	font-size: 25px;
	text-decoration: none;
	margin: auto;
}

	
	
	
	
	
}