.red-all-reserved {
	background-color: red;
	color: white;
	pointer-events: none;
	border-style:solid;
	border-width:1px;
}

.yellow-partial-reserved {
	background-color: yellow;
	color: black;
}

.white-all-free {
	background-color: white;
	color: green;
	border-style:solid;
	border-width:1px;
}

.grey-out-of-order {
	background-color: lightgray;
	color: white;
	pointer-events: none;
}

.green-chosen-time {
	background-color: green;
	color: white;
	border-style:solid;
	border-width:1px;
}



/*reservation and invoice listing styles*/

.reservation-listing-container {

	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	margin-right: auto;
	margin-left: auto;
	background-color: white;
	align-content: center;
	width: 100%;
}

.reservation-list {
	width: 100%;
	background-color: white;
	margin: auto;
	height: 100%;
}

.reservation-listing-container td, tr, th, table {
	border-style: solid;
	border-width: 0.1rem;
	border-spacing: 0;
	border-color: grey;
}


@media screen and (max-width: 800px) {

	.hide-cell-mobile {
	display: none;
}
}


.reservation-fonts {
	font-family: Tahoma;
}


/* calendar styles */
.calendar-container {
	width: 60%;
	background-color: white;
	
	margin-left:auto;
	margin-right:auto;
	
	padding-bottom: 6rem;
}

@media screen and (max-width: 800px) {
	.calendar-container {
	width: 100%;
	background-color: white;
	margin-left: auto;
	margin-right: auto;
	padding-bottom: 6rem;
	
	
}
}



.times {
	width: 100%;
	display: flex;
	flex-direction: row;
}

.chosen-times {
	margin-left: 3rem;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	border-color: black;
	border-style: none;
	border-width: 1px;
	border-radius: 10px;
}

.chosen-times-container {
	display: flex;
	flex-direction: column;
}

.choose-times {
	

	flex-direction: column;


}

.current-reservation-entry {
	margin: 3px;
	text-align: center;
	border-style: solid;
	border-width: 1px;
	border-radius: 10px;
	padding: 5px;
	/*width: 15%;*/
}

.remove-from-reservation {
	border-style:none;
	border-radius: 10px;
}

.final-reservation {
	font-family: Tahoma;
	position: relative;
	right: 0px;
	background-color: green;
	color: white;
	padding: 10px;
	font-size: 30px;
	text-decoration: none;
	border-radius: 10px;

}
.final-reservation:hover {
	box-shadow: 2px 2px darkgreen;
}

.finish-reservation-container {
	text-align: center;
	padding-bottom: 20px;
	margin-top: 50px;
	margin-bottom: 30px;
	position: relative;
}

.reservation-details {
	font-size: 20px;
}

.reservation-details-wrapper {
	margin: auto;
	background-color: white;
	width: 90%;

}
.reservation-form {
	margin-left: 1rem;

}

.reservation-form-headers {
	font-family: Tahoma;
	margin-left: 1rem;
}

.reservation-form-wrapper {
	font-family: Tahoma;
}

.reservation-details-wrapper:hover {
	
	
	border-color: black;

}

.submit-button {
	background-color: green;
	color:white;
	padding: 20px;
	border-radius: 10px;
	font-family: Tahoma;
}

.submit-button:hover {
	background-color:grey;
	color:white;
}

.return-link {
	position: relative;
	top: 0.5rem;
	padding: 0.5rem;
	font-size: 1.3rem;
	background-color: green;
	color: white;
	border-radius: 0.8rem;
	width: 200px;
	text-decoration: none;
	margin-bottom: 1rem;
	font-family: Tahoma;
	text-align: center;
	margin-left: 0.5rem;
}

.return-link:hover {
	color: gold;
}


.update-reservation-info-link-div {
	display: flex;
	flex-direction: column;
}

.update-reservation-info-link {
	position: relative;

	margin-top: 2rem;
}


/*classes for invoices and reservation pages*/

.res-invoice-page {
	background-color: white;
	font-size: 20px;
	font-family: Tahoma;
	padding: 2rem;
}

.spoiler-text {
	display: flex;
	align-items: center;
	justify-content: center;
	border-style: solid;
	border-width: 0px;
	border-color: grey;
	border-radius: 1rem;
	max-width: 20%;
	background-color: lightgray;
	height: 50px;
	margin-bottom: 1rem;


}

details > summary {
	list-style-type: none;
}

.spoiler-text:hover {
	cursor: pointer;
	color: blue;
	background-color: lightblue;
	transition: 1.1s;
}



.management-input {
	width: 50%;
}

.management-input-textarea {
	width: 50%;
	min-height: 20rem;
}


@media screen and (max-width: 800px) {
	.management-input {
		width: 90%;
	}


.management-input-textarea {
	width: 90%;
	
}
}
.management-table {
	max-width: 100%;
}

.management-table-cell {
	
}

.reservation-data-table {
	width: 90%;
	margin: auto;
}

@media screen and (max-width: 800px) {
	.reservation-data-table {
	width: 100%;
	margin: auto;
}
} 


.help-button {
	width: 65px;
	height: 65px;
	margin-left: auto;
	margin-right: 10px;
	margin-top: 10px;
	
	border-radius: 100px;
	z-index: 2;
	border-style: solid;
	border-color: green;
	

}

.help-button:hover {
	background-color: #eee;
	

}


.help-button::before {
	position: relative;
	right: 0.5rem;
	bottom: 0.5rem;
	content: url('../../public/icons/qm.svg');
	
}

.reservation-calendar-header {
	display: flex;
	flex-direction: row;

}

.help-panel {
	margin-right: 10px;
	margin-top: 10px;
	margin-left: auto;
	z-index: 24;
	


}

.help-panel-content {
	width: 100%;
}

.help-panel-close {
	
	margin-right: 10px;
	margin-top: 10px;
	border-style: solid;
	font-size: 2rem;
	font-weight: bold;
	border-radius: 120px;
	border-color: green;
	width: 65px;
	height: 65px;
	display: flex;
	align-items: center;
	justify-content: center;

}

.help-panel-close:hover {
	color: green;
	background-color: #eee;
}

.block {
	width: 2rem;
	height: 1rem;
	display: inline-block;
	border-style: solid;
	border-radius: 8px;
	border-width: 1px;
}

.yellow {
	background-color: yellow;
	
}

.red {
	background-color: red;
}

.grey {
	background-color: lightgrey;
}

.green {
	background-color: green;
}

.blue {
	background-color: #1087ff;
}

.white {
	background-color: white;
}

.darkgrey {
	background-color: grey;
}

.list-invoice-table{
	width: 800px;
}

@media screen and (max-width: 800px) {
	.list-invoice-table{
		width: auto;
	}
}