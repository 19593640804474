@media screen and (min-width: 640px) {

.management-grid {
  min-height: 120vh;
}

.maincontent-grid-left {
  grid-area: main-left;
}

.maincontent-grid-right {
  grid-area: main-right;
}

.maincontent-liftup-grid {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  grid-template-areas: 
    "main-right main-right main-left main-left";
}

}

@media screen and (min-width: 0px) and (max-width: 800px) {

.maincontent-mobile-grid-top {
  margin-top: 1rem;
  grid-area: main-top;
}

.maincontent-mobile-grid-bottom {
  grid-area: main-bottom;
}

.maincontent-liftup-grid {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  grid-template-areas: 
    "main-top"
    "main-bottom";
}


}


/*content management styles*/
.content-management-linkbar {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

@media screen and (min-width: 0px) and (max-width: 800px) {
  .content-management-linkbar {
    flex-direction: column;  
  }

  .management-grid {
    height: auto;
  }
  .management-grid-bottom {
    grid-area: management-bottom;
    padding-bottom: 15rem;
    position:relative;
  }



}

.manage-users-container {

  
}

.reservation-management-header {
  margin-left: 1rem;
  margin-right: 1rem;
}

.content-management-link {
  padding: 1rem;
  background-color: #eee;
  margin-left: 1rem;
  text-decoration: none;
  color: black;
  border-style: solid;
  border-width: 1px;
  margin: 1rem;
}

.content-management-link:hover {
  border-color: grey;
  color: blue;
}

.content-management-dropdown {
  display: none;
  position: absolute;
  top: 2rem;
  z-index: 1;
}

.dropdown:hover .content-management-dropdown {
  display: flex;
  flex-direction: column;
  transition: 1.2s;
  width: 13rem;
}

.management-grid-top {
  grid-area: management-top;
}

.management-grid-middle {
  grid-area: management-middle;
}

.management-grid-bottom {
  grid-area: management-bottom;
}

.management-grid {

  height: auto;
  
  background-color: white;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  grid-template-areas: 
    "management-top management-top management-top management-top"
    "management-middle management-middle management-middle management-middle"
    "management-bottom management-bottom management-bottom management-bottom";


}

