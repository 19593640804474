@media screen and (min-width: 800px) and (max-width: 1200px) {

.mobilelinks {
	display: none;
}

.linkbar-wrapper {
	margin-left: 2rem;
}

.linkbar {
	width: 100%;
	font-family: Tahoma;
	display: flex;
	align-items: center;
	flex-direction: row;
	border-bottom: solid green;
}

.link {
	color: white;
	margin-right: 20px;
	margin-left: 20px;
	text-decoration: none;
	font-size: 15px;
	font-family: Tahoma;
	font-weight: bold;
}

.link:hover {
	color: gold;
}

.dropdown {
  
  display: inline-block;
}

.dropdown-link {
	padding-bottom: 1rem;
	margin-bottom: 1rem;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: green;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding-bottom: 1rem;
  padding-top: 1rem;
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
  transition: 1.2s;
  width: 20rem;
}


.login-div {
	
	background-color: green;
	border-radius: 0.5rem;
	padding: 0.5rem;
	display:flex;
	align-items: center;
	position: absolute;
	right: 1rem;
	top: 1rem;
	z-index: 1;
}

.login {
	
	color: white;
	text-decoration: none;
	font-size: 15px;
	font-family: Tahoma;
	
}

.login:hover {
	
	color: gold;
	
}
}

@media screen and (min-width: 1200px) {

.mobilelinks {
	display: none;
}

.linkbar-wrapper {
	margin-left: 5rem;
}

.linkbar {
	width: 100%;
	font-family: Tahoma;
	display: flex;
	align-items: center;
	flex-direction: row;
	border-bottom: solid green;
}

.link {
	color: white;
	margin-right: 20px;
	margin-left: 20px;
	text-decoration: none;
	font-size: 15px;
	font-family: Tahoma;
	font-weight: bold;
}

.link:hover {
	color: gold;
}

.dropdown {
  
  display: inline-block;
}

.dropdown-link {
	padding-bottom: 1rem;
	margin-bottom: 1rem;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: green;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding-bottom: 1rem;
  padding-top: 1rem;
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
  transition: 1.2s;
  width: 20rem;
}


.login-div {
	
	background-color: green;
	border-radius: 0.5rem;
	padding: 0.5rem;
	display:flex;
	align-items: center;
	position: absolute;
	right: 1rem;
	top: 1.5rem;
	z-index: 1;
}

.login {
	
	color: white;
	text-decoration: none;
	font-size: 15px;
	font-family: Tahoma;
	
}

.login:hover {
	
	color: gold;
	
}



}


@media screen and (min-width: 0px) and (max-width: 800px) {


.mobilelinkbar {
	display: block;
	position: absolute; 
	top: 0rem;
	background-color: green;
	width: 100%;
	min-height: 100%;
	padding-bottom: 10rem;
}

.mobilelink-wrapper {
	margin-top: 3rem;
}


.mobilelinks {
	position: absolute;
	right: 1rem;
}

.mobiletoggler {
	text-decoration: none;
	font-size: 2rem;
	border-style: solid;
	border-radius: 130px;
	border-width: 2px;
	padding-left: 7px;
	padding-right: 7px;
	color: green;
}


.mobiletoggler:hover {
	background-color: green;
	transition: 1.1s;
	color: white;
	border-color: white;
	border-width: 2px;
}

.mobilelink-header {
	color: white;
	border-bottom: solid white;
	margin-left: 0px;
	padding-left: 5px;
}

.mobileclose {
	text-decoration: none;
	position: absolute;
	right: 1rem;
	top: 1rem;
	color: white;
	font-size: 20px;
	font-weight: bold;
	z-index: 4;
}

.mobileclose:hover {
	color: gold;
}

.linkbar {
	padding-top: 1rem;
	padding-bottom: 1rem;
	width: 100%;
	font-family: Tahoma;
	display: flex;
	align-items: center;
	flex-direction: row;
	border-bottom: solid green;
}

.linkbar-wrapper {
	display: none;
}

.link {
	color: white;
	margin: 10px;
	text-decoration: none;
	font-size: 20px;
	font-family: Tahoma;
}

.link:hover {
	color: gold;
}

.login-div {
	margin-bottom: 5rem;
}

.login-wrapper {
	display: none;
}

.login {
	color: white;
	text-decoration: none;
	font-size: 20px;
	margin: 10px;
	font-family: Tahoma;
	margin-bottom: 5rem;
}

.login:hover {
	text-decoration: underline;
}




.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 5px;
  background-color: #1c4b82;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 10px;
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
  transition: 1.2s;
}

}