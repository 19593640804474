/*styles for main liftups*/


/*mobile and tablet screen*/
@media screen and (min-width: 0px) and (max-width: 800px) {

.liftups-container {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	max-width: 96%;
	margin-top: 3rem;
	margin: auto;

}

.liftup-wrapper {
	display:  flex;
	flex-direction: row;
	margin-bottom: 2rem;
	border-style: solid 0;

	/*box-shadow: 1px 2px;*/
}


/*
.liftup-wrapper:hover {
	box-shadow: 2px 3px;
}
*/

.liftup {
	font-family: Tahoma;
	display:  flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	text-decoration: none;
	max-height: 20rem;
	background-color: white;
	border-style: solid 0;
	border-radius: 30px;
	

	
}

.liftup-image {
	width:  50%;
	margin-right: 1rem;
	border-style: solid 0;
	border-radius: 30px 0px 0px 30px;
}

.liftup-text {
	color: black;
	width: 50%;
}
}


/*regular screen*/
@media screen and (min-width: 800px) and (max-width: 1200px) {

.liftups-container {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	max-width: 90%;
	margin-top: 3rem;

}

.liftup-wrapper {
	display:  flex;
	flex-direction: row;
	margin-bottom: 2rem;
	border-style: solid 0;
	border-radius: 30px;
	/*box-shadow: 1px 2px;*/
}

/*
.liftup-wrapper:hover {
	box-shadow: 2px 3px;
	transition: 0.2s;
}
*/

.liftup {
	font-family: Tahoma;
	display:  flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	text-decoration: none;
	max-height: 20rem;
	background-color: white;
	border-style: solid 0;
	border-radius: 30px;

	
}

.liftup-image {
	width:  50%;
	margin-right: 1rem;
	border-style: solid 0;
	border-radius: 30px 0px 0px 30px;
}

.liftup-text {
	color: black;
	
}
}


/*extra-wide screen*/
@media screen and (min-width: 1200px) {

.liftups-container {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	max-width: 70%;
	margin-top: 3rem;

}

.liftup-wrapper {
	display:  flex;
	flex-direction: row;
	margin-bottom: 2rem;
	border-style: solid 0;
	/*box-shadow: 1px 2px;*/
}

/*
.liftup-wrapper:hover {
	box-shadow: 2px 3px;
	transition: 0.2s;
}
*/

.liftup {
	font-family: Tahoma;
	display:  flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	text-decoration: none;
	max-height: 20rem;
	background-color: white;
	border-style: solid 0;
	border-radius: 30px;
	
}

.liftup-image {
	height: 100%;
	width:  50%;
	margin-right: 0.5rem;
	border-style: solid 0;
	border-radius: 30px 0px 0px 30px;
}

.liftup-text {
	color: black;
	white-space: pre-line;
}
}








/*styles for machine and service listing liftups*/

/*mobile screen*/
@media screen and (min-width: 0px) and (max-width: 800px) {

.machine-listing-liftups-container {
	
	max-width: 96%;
	margin-top: 0.3rem;
}

}

/*regular screen*/
@media screen and (min-width: 800px) and (max-width: 1200px) {

.machine-listing-liftups-container {
	max-width: 60%;
	margin-top: 0.5rem;
}

}


/*extra-large screen*/
@media screen and (min-width: 1200px) {

.machine-listing-liftups-container {
	max-width: 40%;
	margin-top: 3rem;
}


}


.machine-listing-liftups-container {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	margin-right: auto;
	margin-left: auto;
}

.machine-listing-liftup-wrapper {
	display:  flex;
	flex-direction: row;
	margin-bottom: 2rem;
	border-style: solid 0;
	

}

/*
.machine-listing-liftup:hover {
	background-color: green;
	transition: 1.1s;
}

.machine-listing-liftup-info:hover {
	
	color: white;
	transition: 1.1s;
}
*/



.machine-listing-liftup {
	width: 100%;
	font-family: Tahoma;
	display:  flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	text-decoration: none;
	max-height: 20rem;
	background-color: white;
	border-radius: 30px;
	/*box-shadow: 1px 2px;*/
}



.machine-listing-liftup-image {
	max-height: 100%;
	width:  50%;
	margin-right: 1rem;
	border-style: solid 0;
	border-radius: 30px 0px 0px 30px;
}

.machine-listing-liftup-info {
	max-width: 40%;
	display:  flex;
	flex-direction: column;
}

.machine-listing-liftup-text {
	color: black;
}




/*styles for individual machine liftups*/

/*mobile screen*/
@media screen and (min-width: 0px) and (max-width: 800px) {

.machine-liftups-container {
	max-width: 100%;
	margin-top: 1rem;
}

.machine-liftup {
	flex-direction: column;
	border-style: solid 0;
	border-radius: 30px;
}

.machine-liftup-image {
	width:  100vw;
	border-style: solid 0;
	border-radius: 30px 30px 0px 0px;
}

.machine-info {
	width: 70%;
	padding-left: 1rem;
}

}

/*regular screen*/
@media screen and (min-width: 800px) and (max-width: 1200px) {

.machine-liftups-container {
	
	max-width: 70%;
	margin-top: 3rem;
}

.machine-liftup {
	flex-direction: row;
	border-style: solid 0;
	border-radius: 30px;
}

.machine-liftup-image {
	width:  70%;
	margin-right: 1rem;
	border-style: solid 0;
	border-radius: 30px 0px 0px 30px;
}

}

/*extra-large screen*/
@media screen and (min-width: 1200px) {
	
.machine-liftups-container {
	
	max-width: 50%;
	margin-top: 3rem;
}

.machine-liftup {
	flex-direction: row;
	border-style: solid 0;
	border-radius: 30px;
}

.machine-liftup-image {
	width:  70%;
	margin-right: 1rem;
	border-style: solid 0;
	border-radius: 30px 0px 0px 30px;
}


}

.machine-liftups-container {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	margin-left: auto;
	margin-right: auto;
	
}



.machine-liftup {
	font-family: Tahoma;
	width: 100%;
	display:  flex;
	justify-content: flex-start;
	text-decoration: none;
	background-color: white;
}

.machine-info {
	display:  flex;
	flex-direction: column;
}

.machine-contact-info {
	width: inherit;
}

.machine-liftup-return {
	font-family: Tahoma;
	text-decoration: none;
	font-size: 30px;
	color: green;
	margin-bottom: 2rem;
}

.machine-liftup-return:hover {
	color: blue;
}




/*styles for news liftups */

/*regular screen*/
@media screen and (min-width: 800px) and (max-width: 1200px) {
.newsliftups-container {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	max-width: 90%;
	margin-top: 3rem;
	margin-left: 10%;
	margin-right: 5%;
}


.newsliftup {
	font-family: Tahoma;
	width: 100%;
	display:  flex;
	flex-direction: column;
	justify-content: flex-start;
	text-decoration: none;
	background-color: white;
	height: auto;
	border-radius: 30px;
	border-style: solid 0;

}

.newsliftup-header {
	color: black;
}

.newsliftup-text {
	color: black;
	white-space: pre-line;
	
}

.news-liftup-image {
	width:  100%;
	height: 50%;
	border-style: solid 0;
	border-radius: 30px 30px 0px 0px;
}

.news-info {
	max-width: 100%;
	display:  flex;
	flex-direction: column;
	padding-left: 10px;
	padding-right: 10px;
}

.news-liftup-date {
	font-size: 10px;
	color: grey;
}
}

/*extra-large screen*/
@media screen and (min-width: 1200px) {
.newsliftups-container {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	max-width: 70%;
	margin-top: 3rem;
	margin-left: 25%;
}


.newsliftup {
	font-family: Tahoma;
	width: 100%;
	display:  flex;
	flex-direction: column;
	justify-content: flex-start;
	text-decoration: none;
	background-color: white;
	height: auto;
	border-style: solid 0;
	border-radius: 30px;

}

.newsliftup-header {
	color: black;
}

.newsliftup-text {
	color: black;
	white-space: pre-line;
	
}

.news-liftup-image {
	height: 50%;
	width:  100%;
	margin-right: 0.5rem;
	border-style: solid 0;
	border-radius: 30px 30px 0px 0px;
}

.news-info {
	max-width: 100%;
	display:  flex;
	flex-direction: column;
	padding-left: 10px;
	padding-right: 10px;
}

.news-liftup-date {
	font-size: 10px;
	color: grey;
}
}


/*mobile screen*/
@media screen and (min-width: 0px) and (max-width: 800px) {
.newsliftups-container {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	max-width: 96%;
	margin-top: 3rem;
	margin: auto;
}


.newsliftup {
	font-family: Tahoma;
	width: 100%;
	display:  flex;
	flex-direction: column;
	justify-content: flex-start;
	text-decoration: none;
	background-color: white;
	height: auto;
	border-radius: 30px;
	border-style: solid 0;

}

.news-liftup-image {
	width:  100%;
	height: 50%;
	border-style: solid 0;
	border-radius: 30px 30px 0px 0px;
}

.newsliftup-header {
	color: black;
}

.newsliftup-text {
	color: black;
	white-space: pre-line;
	
}

.news-info {
	max-width: 100%;
	display:  flex;
	flex-direction: column;
	padding-left: 10px;
	padding-right: 10px;
}

.news-liftup-date {
	font-size: 10px;
	color: grey;
}
}




/*Styles for servicelisting liftups*/

/*mobile screen*/
@media screen and (min-width: 0px) and (max-width: 800px) {

.service-listing-liftups-container {
	
	max-width: 96%;
	margin-top: 0.3rem;
}

}

/*regular screen*/
@media screen and (min-width: 800px) and (max-width: 1200px) {

.service-listing-liftups-container {
	max-width: 60%;
	margin-top: 0.5rem;
}

}


/*extra-large screen*/
@media screen and (min-width: 1200px) {

.service-listing-liftups-container {
	max-width: 40%;
	margin-top: 3rem;
}


}



.service-listing-liftups-container {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	margin-right: auto;
	margin-left: auto;
}

.service-listing-liftup-wrapper {
	display:  flex;
	flex-direction: row;
	margin-bottom: 2rem;
	border-style: solid 0;
	box-shadow: 1px 2px;
}

.service-listing-liftup-wrapper:hover {
	box-shadow: 2px 3px;
}

.service-listing-liftup {
	width: 100%;
	font-family: Tahoma;
	display:  flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	max-height: 20rem;
	background-color: white;
}



.service-listing-liftup-info {
	max-width: 100%;
	display:  flex;
	flex-direction: column;
}

.service-listing-liftup-text {

	color: black;
}

.service-listing-liftup-smalltext {
	color: grey;
	font-size: 15px;
	text-align: center;
}





/*tarvike listing image specs, othewise as machines*/
.tarvike-listing-liftup-image {
	max-height: 100%;
	width:  50%;
	margin-right: 1rem;
}








/* Service page styles */


@media screen and (min-width: 0px) and (max-width: 800px) {

.service-liftups-container {
	
	max-width: 96%;
	margin-top: 3rem;
}

.service-liftup {
	flex-direction: row;
}



}



@media screen and (min-width: 800px) and (max-width: 1200px) {

.service-liftups-container {
	
	max-width: 70%;
	margin-top: 3rem;
}

.service-liftup {
	flex-direction: row;
}


} 




/*extra-large screen*/
@media screen and (min-width: 1200px) {
	
.service-liftups-container {
	
	max-width: 40%;
	margin-top: 3rem;
}

.service-liftup {
	flex-direction: row;
}

}



.service-liftups-container {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	margin-left: auto;
	margin-right: auto;
	
}

.service-text {
	text-align: center;
}

.service-liftup {
	font-family: Tahoma;
	width: 100%;
	display:  flex;
	justify-content: center;
	text-decoration: none;
	background-color: white;
}

.service-info {
	display:  flex;
	flex-direction: column;
}

.service-contact-info {
	width: inherit;
}

.service-liftup-return {
	font-family: Tahoma;
	text-decoration: none;
	font-size: 30px;
	color: green;
	margin-bottom: 2rem;
}

.service-liftup-return:hover {
	color: blue;
}