.logindiv {
	
	
	
	
}

.loginpage-wrapper {
	position: relative; 
	left: 25%;
	width: 50%;
	background-color: #eee;
	padding: 1rem;
}

.loginform {
	
	font-family: Tahoma;
	
	text-align: center;
	
}

.inputfield {
	
	border-radius: 6px;
	border-style: solid;
	border-color: grey;
	font-family: Tahoma;
	
}

.label {
	
	
	
	
}

.button {
	
	padding: 10px;
	background-color: #eee;
	border-radius: 6px;
	font-family: Tahoma;
	
}

.button:hover {
	
	background-color: grey;
	color: white;
	
	
	
}


