.cookie-div {
	width: 100%;
	background-color: green;
	color: white;
	z-index: 4;
	position: fixed;
	bottom: 0px;
	height: 10rem;
	text-align: center;
}

button {
	background-color: white;
	border-radius: 0px;
	color: green;
	padding: 1rem;
}

button:hover {
	background-color: grey;
	color: white;
}