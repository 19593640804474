@media screen and (min-width: 0px) and (max-width: 800px) {

.calendar-frame {
	width: 100%;
	height: 100vh;
}

}


@media screen and (min-width: 800px) and (max-width: 1200px) {

.calendar-wrapper {
	width: 96%;
	margin-left:  auto;
	margin-right: auto;
	margin-top: 1rem;
}

.calendar-frame {
	width: 100%;
	height: 800px;
}


}

@media screen and (min-width: 1200px) {

.calendar-wrapper {
	width: 80%;
	margin: auto;
	margin-top: 2rem;
}

.calendar-frame {
	width: 100%;
	height: 800px;
}


}



