html, body {
	height: 100vh;
}

html { 	
  	background: url('../../public/images/bgimage.jpeg') no-repeat center center fixed; 
  	-webkit-background-size: cover;
  	-moz-background-size: cover;
  	-o-background-size: cover;
  	background-size: cover;

}

body {
	padding: 0;
	margin: 0;
}

.bgimage {
	
}

.routes {
	height: 100vh;
}


.grid-header {
  grid-area: header;
}
.grid-main {
  grid-area: main;
  margin-bottom: auto;
  
}

.grid-footer {
  grid-area: footer;
  position: relative;
  bottom: 0px;
  width: 100%;
  left: 0px;
  right: 0px;

}

.container {
	overflow: hidden;
	position: relative;
  	display: grid;
  	min-height: calc(100vh - 50px);
  	grid-template-columns: auto;
  	grid-template-rows: auto;
  	grid-template-areas: 
    "header header header header"
    "main main main main"
    "footer footer footer footer";
}

@media screen and (max-width: 800px) {
  .container {
  overflow: hidden;
  position: relative;
    display: grid;
    min-height: calc(150vh - 50px);
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas: 
    "header header header header"
    "main main main main"
    "footer footer footer footer";
}

 
}