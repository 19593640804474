/*mobile screens*/
@media screen and (min-width: 0px) and (max-width: 800px) {
.toimintasuunnitelma-wrapper {
	width: 96%;
}
}

/*regular screens*/
@media screen and (min-width: 800px) and (max-width: 1200px) {
.toimintasuunnitelma-wrapper {
	width: 70%;
}

}

/*extra-large screens*/
@media screen and (min-width: 1200px) {
.toimintasuunnitelma-wrapper {
	width: 50%;
}
}



.toimintasuunnitelma-container {
	margin-top: 1rem;
}

.toimintasuunnitelma-wrapper {
	margin: auto;
	background-color: white;
	white-space: pre-line;
	
}

.toimintasuunnitelma {
	padding: 2rem;

}

.toimintakertomus {
	padding: 0.5rem;
}