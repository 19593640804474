/*mobile screens*/
@media screen and (min-width: 0px) and (max-width: 800px) {
.contacts-wrapper {
	width: 96%;
}

.contact-container {
	flex-direction: column;
}

.contact {
	margin: auto;
	width: 100%;
}

h2 {
	text-align: center;
}


}

/*regular screens*/
@media screen and (min-width: 800px) and (max-width: 1200px) {
.contacts-wrapper {
	width: 80%;
}

.contact-container {
	flex-direction: row;
	align-content: flex-start;
	padding-left: 1rem;
}

.contact {
	margin-left: 1rem;
	
	width: 30%;
}

.toimihenkilo-div {
	margin:auto;
}

h2 {
	margin-top: 1rem;
}

}

/*extra-large screens*/
@media screen and (min-width: 1200px) {
.contacts-wrapper {
	width: 60%;
}

.contact-container {
	flex-direction: row;
}

.contact {
	margin-right: 1rem;
	width: 30%;

}

h2 {
	margin-top: 1rem;
}
}




.contact-container {
	display:  flex;
	flex-wrap: wrap;
}

.contacts-wrapper {
	background-color: white;
	margin: auto;
	padding: 1rem;
	margin-top: 1rem;
}

.contact {
	text-align: center; 
	font-size: 1rem;
	font-family: Tahoma;
	position: relative;
	margin-top: 2rem;
	
	
}


.toimihenkilo-div {
	margin-bottom: 6rem;
}




h2 {
	position: relative;
	margin-left: 1rem;
	font-family: Tahoma;
}

.contact-name {
}

.contact-role {
	margin-top: 0rem;
	font-size: 0.7rem;
}

.contact-phone {
}

.contact-email {
	font-size: 0.7rem;
}