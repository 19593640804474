/*mobile screens*/
@media screen and (min-width: 0px) and (max-width: 800px) {
.gallery {
	width: 100%;
}

.images {
	width: 80%;
	margin: auto;
	flex-direction: column;
	flex-wrap: wrap;
}

.thumbnail-wrapper {
	width: 80vw;
}

.thumbnail-wrapper:hover {
	
}

.thumb {
	width: 100%;
}


}

/*regular screens*/
@media screen and (min-width: 800px) and (max-width: 1200px) {
.gallery {
	width: 90%;
}

.thumbnail-wrapper {
	max-width:250px;

}

.thumbnail-wrapper:hover {
	box-shadow: black 2px 3px;
	transition: 0.35s;
}



.thumb {
  width: 250px;
  height: 200px;
}

.thumbnail-description {
	height: 30px;
}


}

/*extra-large screens*/
@media screen and (min-width: 1200px) {
.gallery {
	width: 70%;
}

.thumbnail-wrapper {
	max-width:250px;

}

.thumbnail-wrapper:hover {
	box-shadow: black 2px 3px;
	transition: 0.35s;
}

.thumb {
	width: 250px;
  height: 200px;
}

.thumbnail-description {
	height: 30px;
}

}




.images {
	margin-top: 1rem;
	
	margin: auto;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.gallery {

	margin: auto;
	height: 100%;
	padding-bottom: 0rem;
}

.thumbnail-wrapper {
	
	margin-right: 1rem;
	background-color: white;
}



.thumbnail-link {
	text-decoration: none;
}

.thumbnail-description {
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding-bottom: 1rem;
	padding-right: 1rem;
	padding-left: 1rem;
	color: black;
	
}

.thumb {
  
  z-index: 0;
  position: relative;
}



