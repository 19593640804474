.footerDiv {
	
	height: 150px;
	border-top: solid green;
	font-family: Tahoma;
	display: flex;
	position: relative;
	bottom: 0px;
	right: 0px;
	left: 0px;
	flex-wrap: wrap;
	width: 100%;
	background-color: black;
	filter: opacity(0.7);
	padding-bottom: 3px;
	
	
}

.footer {
	display: flex;
	flex-direction: row;
	filter: opacity(100%);
	color: white;
	position: relative;
	padding: 0px;
	
	
}



.footer-logos {
	display: flex;
	flex-direction: row;
	
	
	margin:auto;
	position: relative;
	
	
}
.footer-logos img {
	margin-left: 10px;
	z-index: 20;
	background-color: white;
	filter: opacity(1.0);
}

.footer-item {
	margin-right: 2rem;
	margin-left: 1rem;
}

/*
@media screen and (min-width: 800px) and (max-width: 1200px) {
	.footerDiv {
		height: 150px;
	}



}

*/

@media screen and (min-width: 0px) and (max-width: 800px) {
.footerDiv {
	
	height: 500px;
	flex-direction: column;
	
	
}

.footer {
	flex-direction: column;
	align-items: center;
	
}


.footer-logos {
	flex-direction: column;
	align-item:center;
	width: 100%;
	position: relative;
	max-width: 200px;
	
}

.footer-logos img {
	max-width: 200px;
	margin-left: auto;
	margin-right:auto;
	margin-top: 1rem;
	z-index: 20;
	background-color: white;
	filter: opacity(1.0);

}


}





