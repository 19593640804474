/*mobile screens*/
@media screen and (min-width: 0px) and (max-width: 800px) {

.joinmember-wrapper {
	margin-top: 0.3rem;
	width: 96%;
	
}
}

/*regular screens*/
@media screen and (min-width: 800px) and (max-width: 1200px) {

.joinmember-wrapper {
	margin-top: 0.5rem;
	width: 70%;
	
}
}

/*extra-large screens*/
@media screen and (min-width: 1200px) {

.joinmember-wrapper {
	margin-top: 1rem;
	width: 50%;
	
}

}

.joinmember-container {
	height: 100vh;
	margin-top: 1rem;
}

.joinmember-wrapper {
	margin: auto;
	background-color: white;
	white-space: pre-line;
}

.joinmember {
	padding: 2rem;
}