.yhdistys-container {
	margin-top: 1rem;
	width: 100%;
	
	background-color: white;
}

/*mobile screens*/
@media screen and (min-width: 0px) and (max-width: 800px) {

.yhdistys-wrapper {
	margin-top: 1rem;
	width: 96%;

}




}

/*regular screens*/
@media screen and (min-width: 800px) and (max-width: 1200px) {

.yhdistys-wrapper {
	margin-top: 1rem;
	width: 70%;
}

}

/*extra-large screens*/
@media screen and (min-width: 1200px) {

.yhdistys-wrapper {
	margin-top: 1rem;
	width: 50%;
}

}




.yhdistys-wrapper {
	margin: auto;
	background-color: white;
	white-space: pre-line;
	
	
}

.yhdistys {
	padding: 2rem;

}


.content-image {
	max-width: 60%;
}

.projectfunding-container {
	width: 60%;
	margin: auto;
	background-color: white;
	height: 500px;
	padding: 2rem;

}

.project-logos {
	display: flex;
	justify-content: space-between;
}

@media screen and (min-width: 0px) and (max-width: 800px) {

.projectfunding-container {
	width: 100%;
	margin: auto;
	background-color: white;
	height: 500px;
	padding: 0.5rem;

}

.project-logos {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.project-logos img {
	margin-bottom: 1rem;
}




}

